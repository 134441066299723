import { apiRequest } from "utils/async/apiUtils";

// export const loadPermissionData = async (setLoaded = () => {}) => {
//   setLoaded(false);

//   try {
//     const res = await apiRequest("get", "permission/list", {});
//     console.log(res, "rrrrr");
//     setLoaded(true);
//     return res;
//   } catch (err) {
//     setLoaded(true);
//     return err.response;
//   }
// };

export const loadPermissionData = async (params, setLoaded = () => {}) => {
  setLoaded(false);

  try {
    const res = await apiRequest("get", "permission/list", {
      queries: {
        ...params,
        searchValue: "role",
        //   selectValue:
        //     "title, description, slug, image, content, status, createdAt, ordering",
      },
    });

    const data = res.data;
    setLoaded(true);
    return data;
  } catch (err) {
    setLoaded(true);
    return err.response;
  }
};

export const addPermissionService = async (params, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    const res = await apiRequest("post", "permission/add", {
      body: {
        ...params,
      },
    });
    setLoaded(true);
    return res;
  } catch (err) {
    setLoaded(true);
    return err.response;
  }
};

export const togglePermissionService = async (params, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    const res = await apiRequest("patch", "permission/toggle-permission", {
      body: {
        ...params,
      },
    });
    setLoaded(true);
    return res;
  } catch (err) {
    setLoaded(true);
    return err.response;
  }
};
